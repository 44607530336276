import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { PractitionerReviewFields } from '../../pages/ReviewPractitioner/ReviewPractitioner'
import { useTranslation } from 'react-i18next'
import * as S from './ConditionalQuestion.styles'
import { Radio, RadioGroup } from '@mui/material'

export const ConditionalQuestion = ({
  name,
  label,
  control,
}: {
  name:
    | 'conditionalQuestions.teethPosition'
    | 'conditionalQuestions.grindingProtection'
    | 'conditionalQuestions.teethColor'
  label: string
  control: Control<PractitionerReviewFields, PractitionerReviewFields>
}) => {
  const { t } = useTranslation()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <S.FormControl>
            <S.FormLabel focused={false}>{label}</S.FormLabel>
            <RadioGroup
              {...field}
              onChange={(e) => {
                field.onChange(e.target.value === 'true')
              }}
            >
              <S.FormControlLabel value={true} control={<Radio />} label={t('yes')} />
              <S.FormControlLabel value={false} control={<Radio />} label={t('no')} />
            </RadioGroup>
          </S.FormControl>
        )
      }}
    ></Controller>
  )
}
