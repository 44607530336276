import { Container as ContainerBase, ListItemText } from '@mui/material'

import { styled } from '@mui/material'

export const Container = styled(ContainerBase)(({ theme }) => ({
  maxWidth: '592px',
  width: '100%',
  margin: 'auto',
  textAlign: 'center',
  padding: theme.spacing(3),
}))

export const Option = styled(ListItemText)(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'flex-start!important',
}))
