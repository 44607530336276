import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import ResizeSensor from 'css-element-queries/src/ResizeSensor'

import ReviewPractitioner from '../pages/ReviewPractitioner/ReviewPractitioner'
import ReviewClinic from '../pages/ReviewClinic/ReviewClinic'
import FinishPage from '../screens/Finish/Finish'
import CreatePage from '../pages/Create/Create'
import ClinicWrapper from '../components/ClinicWrapper/ClinicWrapper'
import { postParentMsg } from '../common/utils/MessageSender'
import { IframeMessageType } from '../common/@types/HappyMessages'
import { useQueryParameterContext } from '../providers/QueryParameterProvider'
import { QueryClientProvider } from 'react-query'
import queryClient from './queryClient'
import { EmailFeedback } from '../pages/EmailFeedback/EmailFeedback'
import { routes } from './routes'

type WrapperProps = { isEmbedded: boolean }

const Wrapper = ({ isEmbedded, children }: React.PropsWithChildren<WrapperProps>) => {
  let screenSize = 'none'

  function adjustScreen(postMessage: MessageEvent) {
    if (postMessage.data.type === IframeMessageType.SCREEN_SIZE_CHANGE) {
      screenSize = postMessage.data.payload === 'small' ? '640px' : 'none'
    }
  }

  if (window.addEventListener) {
    // For standards-compliant web browsers
    window.addEventListener('message', adjustScreen, false)
  }

  if (isEmbedded) {
    return (
      <React.Fragment>
        <div style={{ maxWidth: screenSize }}>{children}</div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <ClinicWrapper />
      {children}
    </React.Fragment>
  )
}

const AppRouter: React.FC = () => {
  const queryParams = useQueryParameterContext()
  const isEmbedded = queryParams.embedded

  useEffect(() => {
    if (isEmbedded) {
      const rootElement = document.getElementById('root')

      if (rootElement) {
        const resizeSensor = new ResizeSensor(rootElement, ({ height }: { height: number }) => {
          postParentMsg({
            type: IframeMessageType.HEIGHT_CHANGE,
            payload: height,
          })
        })

        postParentMsg({
          type: IframeMessageType.SCROLL_TO_TOP,
        })
        return resizeSensor.detach
      }
    }

    return () => {}
  }, [isEmbedded])

  return (
    <div>
      <Wrapper isEmbedded={isEmbedded}>
        <QueryClientProvider client={queryClient}>
          <Switch>
            <Route exact path={routes.default}>
              <Redirect to={routes.reviewPractitioner} />
            </Route>

            <Route exact path={routes.reviewPractitioner} component={ReviewPractitioner} />
            <Route exact path={routes.reviewClinic} component={ReviewClinic} />
            <Route exact path={routes.finish} component={FinishPage} />
            <Route exact path={routes.create} component={CreatePage} />
            <Route exact path={routes.emailFeedback} component={EmailFeedback} />
          </Switch>
        </QueryClientProvider>
      </Wrapper>
    </div>
  )
}

export default AppRouter
