import { Typography, Container as ContainerBase } from '@mui/material'

import { styled } from '@mui/material'

export const Container = styled(ContainerBase)(({ theme }) => ({
  maxWidth: '592px',
  width: '100%',
  margin: 'auto',
  textAlign: 'center',
  padding: theme.spacing(3),
  marginTop: theme.spacing(5),
  display: 'flex',
  flexDirection: 'column',
}))

export const Title = styled(Typography)({})

Title.defaultProps = {
  variant: 'Lato H4',
}

export const Subtitle = styled(Typography)({
  marginBottom: 10,
})

Subtitle.defaultProps = {
  variant: 'Lato Body 1',
}
