export enum IframeMessageType {
  INIT = 'INIT',
  HEIGHT_CHANGE = 'HEIGHT_CHANGED',
  REDIRECT_TO_JAMEDA = 'REDIRECT_TO_JAMEDA',
  SCREEN_SIZE_CHANGE = 'SCREEN_SIZE_CHANGE',
  SCROLL_TO_TOP = 'REDIRECT_TO_TOP',
  REDIRECT = 'REDIRECT',
}

type BaseEvent = {
  target?: string
  data?: Record<string, unknown>
}

export type HappyEvent =
  | (BaseEvent & {
      type: IframeMessageType.HEIGHT_CHANGE
      payload: number
    })
  | (BaseEvent & {
      type: IframeMessageType.REDIRECT_TO_JAMEDA
      payload: string
    })
  | (BaseEvent & {
      type: IframeMessageType.SCREEN_SIZE_CHANGE
      payload: 'large' | 'small'
    })
  | (BaseEvent & {
      type: IframeMessageType.SCROLL_TO_TOP
    })
  | (BaseEvent & {
      type: IframeMessageType.REDIRECT
      payload: string
    })
  | (BaseEvent & {
      type: IframeMessageType
      payload: never
    })
