import React, { useState } from 'react'
import { Appointment } from '../../common/@types/interfaces'
import { getAppointmentsWithoutSurvey } from '../../common/api/happy'
import { routes } from '../../config/routes'
import { useQueryParameterContext } from '../../providers/QueryParameterProvider'
import CreateScreen from '../../screens/Create/Create'
import { useDidMount } from '../../common/hooks/useDidMount'

const CreatePage: React.FC = () => {
  const [appointments, setAppointments] = useState<Appointment[]>([])
  const queryParamsContext = useQueryParameterContext()

  const handleRedirectToReview = () => {
    window.location.replace(`#${routes.reviewPractitioner}`)
  }

  useDidMount(() => {
    if (process.env.REACT_APP_ENV !== 'production' && queryParamsContext.appointmentIds.length === 0) {
      getAppointmentsWithoutSurvey().then((data: Appointment[]) => {
        setAppointments(data)
      })
    } else {
      handleRedirectToReview()
    }
  })

  return <CreateScreen appointments={appointments} />
}

export default CreatePage
