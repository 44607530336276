import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ToothCareIcon } from '../../assets/icons/tooth-care-icon.svg'
import * as S from './Finish.styles'
import { Grid } from '@mui/material'

const Finish = () => {
  const { t } = useTranslation()

  return (
    <S.Container>
      <Grid>
        <ToothCareIcon />
      </Grid>
      <S.Title>{t('thank_you')}</S.Title>
      <S.Subtitle>{t('thank_you_long')}</S.Subtitle>
    </S.Container>
  )
}

export default Finish
