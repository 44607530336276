import React, { useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import { parseAppointments, parseRating } from '../common/utils/ParamUtils'

interface QueryParameterContext {
  rating: number
  appointmentIds: string[]
  embedded: boolean
  source?: string
}

//  Default Context constructor
export const Context = React.createContext<QueryParameterContext>({
  rating: 0,
  appointmentIds: [],
  embedded: true,
})

//  Get Default Context method
export const useQueryParameterContext = (): QueryParameterContext => useContext(Context)

const QueryParameterProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation()

  const queryString = window.location.search || location.search
  const queryParams = (queryString ? qs.parse(queryString) : window.env) || {} // Not embedded and fallback iframe -> query params , embedded -> env set by launcher.ts

  const appointmentIds = parseAppointments(queryParams.appointmentReferenceIds as string)
  const rating = parseRating(queryParams.selectedRating as string)
  const { embedded, source } = queryParams

  const value = useMemo(
    () => ({
      rating,
      appointmentIds,
      embedded: embedded !== false && embedded !== 'false',
      source: source as string,
    }),
    [rating, appointmentIds, embedded, source]
  )

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export default QueryParameterProvider
