import { Box } from '@mui/material'

import { styled } from '@mui/material'

export const Root = styled(Box)({
  marginTop: 24,
  maxWidth: 630,
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
})
