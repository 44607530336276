import { Box, Button } from '@mui/material'

import { styled } from '@mui/material'
import theme from '../../theme'

export const Option = styled(Button, {
  shouldForwardProp: (prop: string) => !['active'].includes(prop),
})<{ active: boolean }>(({ active }) => ({
  opacity: active ? 1 : 0.3,
  padding: 0,
  margin: 0,
  minWidth: theme.spacing(6),
}))

Option.defaultProps = {
  variant: 'borderless',
}

export const Options = styled(Box)({
  display: 'flex',
  flexWrap: 'nowrap',
})
