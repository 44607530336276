import { ReviewCategory } from '../../../../common/@types/enums'

export default interface IssueOptions {
  type: IssueType
  title: string
}

export enum IssueType {
  CLINIC_FRIENDLINESS = 'CLINIC_FRIENDLINESS',
  CLINIC_COMPETENCE = 'CLINIC_COMPETENCE',
  CLINIC_CLEANLINESS = 'CLINIC_CLEANLINESS',
  CLINIC_PUNCTUALITY = 'CLINIC_PUNCTUALITY',
  DOCTOR_OVERALL_RESULT = 'DOCTOR_OVERALL_RESULT',
  DOCTOR_REQUIRED_TIME = 'DOCTOR_REQUIRED_TIME',
  DOCTOR_FRIENDLINESS = 'DOCTOR_FRIENDLINESS',
  DOCTOR_COMPETENCE = 'DOCTOR_COMPETENCE',
  DOCTOR_ENJOYMENT = 'DOCTOR_ENJOYMENT',
  EXPERT_OVERALL_RESULT = 'EXPERT_OVERALL_RESULT',
  EXPERT_REQUIRED_TIME = 'EXPERT_REQUIRED_TIME',
  EXPERT_FRIENDLINESS = 'EXPERT_FRIENDLINESS',
  EXPERT_COMPETENCE = 'EXPERT_COMPETENCE',
  EXPERT_ENJOYMENT = 'EXPERT_ENJOYMENT',
}

export const clinicIssues: IssueOptions[] = [
  {
    type: IssueType.CLINIC_FRIENDLINESS,
    title: 'sub_reviews.clinic.friendliness.title',
  },
  {
    type: IssueType.CLINIC_COMPETENCE,
    title: 'sub_reviews.clinic.competence.title',
  },
  {
    type: IssueType.CLINIC_CLEANLINESS,
    title: 'sub_reviews.clinic.cleanliness.title',
  },
  {
    type: IssueType.CLINIC_PUNCTUALITY,
    title: 'sub_reviews.clinic.punctuality.title',
  },
]
export const doctorIssues: IssueOptions[] = [
  {
    type: IssueType.DOCTOR_OVERALL_RESULT,
    title: 'sub_reviews.doctor.overall_result.title',
  },
  {
    type: IssueType.DOCTOR_REQUIRED_TIME,
    title: 'sub_reviews.doctor.required_time.title',
  },
  {
    type: IssueType.DOCTOR_FRIENDLINESS,
    title: 'sub_reviews.doctor.friendliness.title',
  },
  {
    type: IssueType.DOCTOR_COMPETENCE,
    title: 'sub_reviews.doctor.competence.title',
  },
  {
    type: IssueType.DOCTOR_ENJOYMENT,
    title: 'sub_reviews.doctor.enjoyment.title',
  },
]

export const expertIssues: IssueOptions[] = [
  {
    type: IssueType.EXPERT_OVERALL_RESULT,
    title: 'sub_reviews.expert.overall_result.title',
  },
  {
    type: IssueType.EXPERT_REQUIRED_TIME,
    title: 'sub_reviews.expert.required_time.title',
  },
  {
    type: IssueType.EXPERT_FRIENDLINESS,
    title: 'sub_reviews.expert.friendliness.title',
  },
  {
    type: IssueType.EXPERT_COMPETENCE,
    title: 'sub_reviews.expert.competence.title',
  },
  {
    type: IssueType.EXPERT_ENJOYMENT,
    title: 'sub_reviews.expert.enjoyment.title',
  },
]

export const IssuesByReviewCategory: Map<ReviewCategory, IssueOptions[]> = new Map([
  [ReviewCategory.EXPERT, expertIssues],
  [ReviewCategory.DOCTOR, doctorIssues],
  [ReviewCategory.CLINIC, clinicIssues],
])
