import { Container as ContainerBase, Typography, Rating as RatingBase } from '@mui/material'

import { styled } from '@mui/material'
import { matchesDesktop } from '../../../../helpers/breakpoints'

export const RatingLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  textAlign: 'center',
}))

RatingLabel.defaultProps = {
  variant: 'Lato Body 2',
}

export const Container = styled(ContainerBase)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: 67,
  maxWidth: 67,
})

export const Rating = styled(RatingBase)(({ theme }) => {
  return {
    margin: 'auto',
    marginTop: theme.spacing(2),

    [matchesDesktop(theme)]: {
      [`label`]: {
        maxWidth: 84,
      },
    },
  }
})
