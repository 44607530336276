import { matchesMobile } from '../../helpers/breakpoints'
import theme from '../../theme'
import { useEffect, useState } from 'react'

// Scroll the chips to indicate horizontal scrolling
export const useMoveChips = (canMove: boolean): void => {
  const [wereChipsMoved, setWereChipsMoved] = useState(false)

  // use async call to put the scrolling in the event loop (to be executed after the page is fully loaded)
  useEffect(() => {
    if (matchesMobile(theme) && canMove && !wereChipsMoved) {
      setTimeout(() => {
        const chipsContainer = document.querySelector('#issue-container')
        const chips = chipsContainer?.querySelectorAll(`[data-class="issue-chip"]`)
        const secondChip = chips ? chips[1] : null
        const thirdChip = chips ? chips[2] : null
        if (secondChip && thirdChip) {
          const startOfSecondChip = secondChip.getBoundingClientRect().left
          const endOfThirdChip = thirdChip.getBoundingClientRect().right
          const distanceFromStartOfSecondChipToEndOfThirdChip = endOfThirdChip - startOfSecondChip
          const amountToScroll = distanceFromStartOfSecondChipToEndOfThirdChip / 2
          chipsContainer?.scrollTo(amountToScroll, 0)
        }
        setWereChipsMoved(true)
      }, 0)
    }
  }, [canMove, wereChipsMoved])
}
