import React, { useState, useEffect } from 'react'
import { HashRouter as Router } from 'react-router-dom'
import queryString from 'query-string'

import AppRouter from './config/AppRouter'
import { I18nextProvider } from 'react-i18next'
import * as i18n from './config/i18n'
import theme from './theme'
import QueryParameterProvider from './providers/QueryParameterProvider'
import { initializeSentry } from './services/sentry'
import Logger from './services/logger'
import { PosthogProvider } from './providers/PosthogProvider'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material'

initializeSentry()

const App: React.FC = () => {
  const [loadingTranslations, setLoadingTranslations] = useState(true)

  useEffect(() => {
    const rejectionCallback = (event: PromiseRejectionEvent) => {
      Logger.error('UnhandledRejection', event.reason)
    }

    window.addEventListener('unhandledrejection', rejectionCallback)

    const urlParams = queryString.parse(window.location.search)

    const lang = urlParams?.lang as string | undefined

    i18n.init(lang).finally(() => setLoadingTranslations(false))

    return () => window.removeEventListener('unhandledrejection', rejectionCallback)
  }, [setLoadingTranslations])

  if (loadingTranslations) return <div />

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <PosthogProvider
          apiKey={process.env.REACT_APP_POSTHOG_API_KEY}
          apiHost={process.env.REACT_APP_POSTHOG_API_HOST}
        >
          <QueryParameterProvider>
            <I18nextProvider i18n={i18n.default}>
              <CssBaseline />
              <AppRouter />
            </I18nextProvider>
          </QueryParameterProvider>
        </PosthogProvider>
      </ThemeProvider>
    </Router>
  )
}

export default App
