import React, { useEffect } from 'react'
import posthog from 'posthog-js'

type PosthogProviderProps = {
  apiKey?: string
  apiHost?: string
}

export const PosthogProvider = ({ apiKey, apiHost, children }: React.PropsWithChildren<PosthogProviderProps>) => {
  useEffect(() => {
    if (!apiKey?.trim?.()) return

    posthog.init(apiKey, { api_host: apiHost, persistence: 'localStorage' })
  }, [apiKey, apiHost])

  return <>{children}</>
}
