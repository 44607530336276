import { colors } from '@dentalux/ui-library-core/cjs'
import { matchesDesktop, matchesMobile } from '../../../../helpers/breakpoints'
import { Box, chipClasses } from '@mui/material'

import { Chip as ChipBase } from '@mui/material'

import { styled } from '@mui/material'

export const Chip = styled(ChipBase)(({ theme }) => ({
  margin: 4,

  [`&.${chipClasses.colorSecondary}`]: {
    color: colors.grey[400],
    border: `1px solid ${colors.grey[400]}`,
  },

  [matchesMobile(theme)]: {
    flexBasis: 0,
    flexGrow: 1,
  },
}))

export const Root = styled(Box)(({ theme }) => ({
  [matchesDesktop(theme)]: {
    marginLeft: 60,
    maxWidth: 450,
    alignSelf: 'center',
    flexWrap: 'wrap',
    display: 'flex',
  },
}))
