import React from 'react'

import i18n from '../../config/i18n'
import * as S from './LanguageSwitch.styles'

const LanguageSwitch: React.FC<{ className?: string }> = ({ className }) => {
  const handleLanguageChange = (language: 'de' | 'en') => () => {
    i18n.changeLanguage(language)
  }

  return (
    <S.Options>
      <S.Option active={i18n.language === 'en'} onClick={handleLanguageChange('en')}>
        EN
      </S.Option>
      <S.Option active={i18n.language === 'de'} onClick={handleLanguageChange('de')}>
        DE
      </S.Option>
    </S.Options>
  )
}

export default LanguageSwitch
