import { matchesMobile } from '../../helpers/breakpoints'
import { Box, Typography } from '@mui/material'
import { ReactComponent as MovingForwardToImage } from '../../../src/assets/images/moving-forward-to.svg'

import { styled } from '@mui/material'
import theme from '../../theme'

export const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  [matchesMobile(theme)]: {
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
}))

export const DescriptionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 480,
  marginRight: 70,

  [matchesMobile(theme)]: {
    marginRight: 0,
    textAlign: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
}))

export const Title = styled(Typography)({
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(3),

  [matchesMobile(theme)]: {
    ...theme.typography['Lato H4'],
  },
})

Title.defaultProps = {
  variant: 'Lato H2',
}

export const Subtitle = styled(Typography)({
  marginBottom: theme.spacing(3),
})

Subtitle.defaultProps = {
  variant: 'Lato Body 1',
}

export const ImageContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(8),

  [matchesMobile(theme)]: {
    marginTop: 0,
  },
})

export const Image = styled(MovingForwardToImage)(({ theme }) => ({
  width: 496,

  [matchesMobile(theme)]: {
    width: '100%',
    maxWidth: 500,
    marginTop: 0,
  },
}))
