import { Practitioner } from '../@types/interfaces'

export const parsePractitioners = (val: string | undefined): Practitioner[] => {
  const practitionerIds = val ? val.split(',') : []

  return practitionerIds.map((id) => ({
    name: 'Unknown',
    referenceId: id,
  }))
}

export const parseAppointments = (val: string | undefined): string[] => (val ? val.split(',') : [])

export const parseRating = (val: string | undefined): number => (val ? Number(val) : 0)
