import {
  FormControl as FormControlBase,
  FormLabel as FormLabelBase,
  FormControlLabel as FormControlLabelBase,
} from '@mui/material'

import { styled } from '@mui/material'

export const FormControl = styled(FormControlBase)({
  alignSelf: 'stretch',
  marginTop: 24,
  marginBottom: 8,
  flexGrow: 1,
})

export const FormLabel = styled(FormLabelBase)(({ theme }) => ({
  ...theme.typography['Lato Body 1'],
  textAlign: 'left',
}))

export const FormControlLabel = styled(FormControlLabelBase)(({ theme }) => ({
  ...theme.typography['Lato Body 1'],

  '& .Mui-checked': {
    color: theme.palette.primary.main,
  },
}))
