import { HappyEvent } from '../@types/HappyMessages'

export const postParentMsg = (msg: HappyEvent) => {
  if (window.parent) {
    window.parent.postMessage(msg, '*')
  }
}

export const postHappyMsg = (msg: HappyEvent) => {
  const happyIframe = document.getElementById('happy-iframe') as HTMLIFrameElement

  if (happyIframe) {
    happyIframe.contentWindow?.postMessage(msg, '*')
  }
}
