import { useEffect } from 'react'

function useDidMount(callback: () => unknown): void {
  useEffect(() => {
    if (typeof callback === 'function') {
      callback()
    }
    // Ignoring the dependencies to make it run only when the component mounts.
  }, []) //eslint-disable-line
}

export { useDidMount }
