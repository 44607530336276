import React, { useCallback, useEffect, useState } from 'react'
import { Clinic, Practitioner, Review, Survey } from '../../common/@types/interfaces'
import * as api from '../../common/api/happy'
import { createOrGetSurvey } from '../../common/api/happy'
import ReviewScreen from '../../screens/Review/Review'
import RedirectScreen from '../../screens/Redirect/Redirect'
import { routes } from '../../config/routes'
import { useQueryParameterContext } from '../../providers/QueryParameterProvider'
import { ReviewType, SurveyStatus } from '../../common/@types/enums'
import {
  handleAddIssue,
  handleDeleteIssue,
  handleRedirectToFinish,
  handleRedirectToThirdPartySite,
  handleRedirectToUrl,
  handleRedirectWithScreen,
  isReviewPositive,
  useAddIssueMutation,
  useDeleteIssueMutation,
} from '../../helpers/Review.helper'
import useReviewContext, {
  defaultConditionalQuestions,
  defaultConditionalQuestionsAnswers,
  getClinicReview,
  getPractitionerReview,
  UpdateReviewProps,
} from '../../store/ReviewContext'
import { useDebounce } from 'use-debounce'
import { useForm } from 'react-hook-form'
import useSurveyContext from '../../store/SurveyContext'
import useClinicContext from '../../store/ClinicContext'
import defaultPractitionerImageUrl from '../../assets/icons/doctor-silhouette.jpg'
import getPublicAsset from '../../helpers/getPublicAsset'
import noOp from '../../helpers/noOp'
import Logger from '../../services/logger'
import { ConditionalQuestion } from '../../components/ConditionalQuestion/ConditionalQuestion'
import { useTranslation } from 'react-i18next'
import { ConditionalQuestions } from '../../components/ConditionalQuestions/ConditionalQuestions'
import { MAX_RATING } from '../../common/constants'

export type PractitionerReviewFields = {
  conditionalQuestions: {
    teethPosition: boolean | null
    grindingProtection: boolean | null
    teethColor: boolean | null
  }
  feedback: Review['feedback']
}

const ReviewPractitioner: React.FC = () => {
  const queryParamsContext = useQueryParameterContext()
  const surveyContext = useSurveyContext()
  const clinicContext = useClinicContext()
  const reviewContext = useReviewContext()
  const review = getPractitionerReview(reviewContext)
  const clinicReview = getClinicReview(reviewContext)
  const [showRedirectScreen, setShowRedirectScreen] = useState(false)
  const [reviewQuestions, setReviewQuestions] = useState(false)
  const [conditionalQuestionsNeeded, setConditionalQuestionsNeeded] = useState(defaultConditionalQuestions)
  const conditionalQuestionsShown = !!Object.values(conditionalQuestionsNeeded).find((q) => q)
  const reviewQuestionsShown = reviewQuestions || !!review?.rating
  const { t } = useTranslation()

  const { control, ...form } = useForm<PractitionerReviewFields>({
    mode: 'onChange',
    defaultValues: {
      conditionalQuestions: review?.conditionalQuestionsAnswers || defaultConditionalQuestionsAnswers,
      feedback: review?.feedback || null,
    },
  })

  const [debouncedValues] = useDebounce(form.getValues(), 1000)

  const handleUpdateFeedback = useCallback(() => {
    const {
      feedback,
      conditionalQuestions: { grindingProtection, teethColor, teethPosition },
    } = debouncedValues

    if (
      review &&
      (feedback !== review.feedback ||
        (Object.values(conditionalQuestionsNeeded || {}).find((entry) => !!entry) &&
          (grindingProtection !== review.conditionalQuestionsAnswers?.grindingProtection ||
            teethColor !== review.conditionalQuestionsAnswers?.teethColor ||
            teethPosition !== review.conditionalQuestionsAnswers?.teethPosition)))
    ) {
      api
        .updateReview({
          feedback,
          referenceId: review.reviewReferenceId,
          entityType: review.entityType,
          grindingProtection: typeof grindingProtection === 'boolean' ? grindingProtection : undefined,
          teethColor: typeof teethColor === 'boolean' ? teethColor : undefined,
          teethPosition: typeof teethPosition === 'boolean' ? teethPosition : undefined,
        })
        .then(() => updateReview(debouncedValues))
    }
    // eslint-disable-next-line
  }, [
    debouncedValues.feedback,
    debouncedValues.conditionalQuestions.grindingProtection,
    debouncedValues.conditionalQuestions.teethColor,
    debouncedValues.conditionalQuestions.teethPosition,
  ])

  const handleSubmit = () => {
    window.location[queryParamsContext.embedded ? 'replace' : 'assign'](
      `#${routes.reviewClinic}?embedded=${queryParamsContext.embedded}`
    )
  }

  const updateReview = ({ value, feedback, issues }: Omit<UpdateReviewProps, 'referenceId'>) => {
    if (review) {
      reviewContext.updateReview({
        referenceId: review?.reviewReferenceId as string, // updateReview will be called only when review is not undefined
        value,
        feedback,
        issues,
      })
    }
  }

  const { mutateAsync: addIssue, isLoading: isAddIssueLoading } = useAddIssueMutation(review, updateReview)

  const { mutateAsync: deleteIssue, isLoading: isDeleteIssueLoading } = useDeleteIssueMutation()

  const handleShowRedirectScreen = () => {
    setShowRedirectScreen(true)
  }

  const handleUpdateRating =
    (practitionerReferenceId: string, clinicReferenceId: string, entityType: ReviewType) => (rating: number) => {
      const firstRating = review?.rating === 0

      api
        .updateReview({
          referenceId: practitionerReferenceId,
          rating: rating,
          entityType: entityType,
        })
        .then(() => {
          updateReview({
            value: rating,
          })

          if (firstRating && rating === 10 && !conditionalQuestionsShown) {
            api
              .updateReview({
                referenceId: clinicReferenceId,
                rating: rating,
                entityType: ReviewType.CLINIC,
              })
              .then(() => {
                if (surveyContext.survey) {
                  api.submitSurvey(surveyContext.survey.surveyReferenceId).then((data: Survey) => {
                    surveyContext.setSurvey(data)
                    handleRedirectWithScreen(queryParamsContext.embedded, handleShowRedirectScreen, data.redirectToUrl)
                  })
                }
              })
          } else {
            setReviewQuestions(true)
          }
        })
    }

  useEffect(() => {
    handleUpdateFeedback()
  }, [handleUpdateFeedback])

  useEffect(() => {
    Logger.debug('App Entry information', {
      appointmentIds: queryParamsContext.appointmentIds,
      rating: queryParamsContext.rating,
      source: queryParamsContext.source,
    })

    const canCreateOrGetSurvey =
      queryParamsContext.appointmentIds?.length > 0 && Number.isInteger(queryParamsContext.rating)

    if (!canCreateOrGetSurvey) {
      Logger.warn('Trying to call /api/survey with wrong params from URL', { queryParamsContext })
      return
    }

    createOrGetSurvey({
      appointmentIds: queryParamsContext.appointmentIds,
      rating: queryParamsContext.rating,
      source: queryParamsContext.source,
    }).then((data) => {
      if (!data) {
        return
      }

      Logger.debug('Survey information', { ...data })
      const { redirectToUrl, status, reviews, surveyReferenceId } = data

      if (queryParamsContext.rating === MAX_RATING && status !== SurveyStatus.SUBMITTED) {
        const postSurveyAndRedirectToGoogle = async () => {
          await Promise.all(
            reviews.map((review) =>
              api.updateReview({
                referenceId: review.reviewReferenceId,
                rating: queryParamsContext.rating,
                entityType: review.entityType,
              })
            )
          )

          const response = await api.submitSurvey(surveyReferenceId)

          if (response.redirectToUrl) handleRedirectToUrl(response.redirectToUrl, queryParamsContext.embedded)
        }

        postSurveyAndRedirectToGoogle()

        return
      }

      surveyContext.setSurvey(data)

      if (status === SurveyStatus.SUBMITTED) {
        if (isReviewPositive(reviews)) {
          handleRedirectWithScreen(queryParamsContext.embedded, handleShowRedirectScreen, redirectToUrl)
        } else {
          handleRedirectToFinish(queryParamsContext.embedded)
        }
      } else {
        reviewContext.setReviews(reviews)
        const practitionerReview = reviews.find((review) => review.entityType === ReviewType.PRACTITIONER)

        form.setValue('feedback', practitionerReview?.feedback || null)
        form.setValue(
          'conditionalQuestions',
          practitionerReview?.conditionalQuestionsAnswers || defaultConditionalQuestionsAnswers
        )

        if (practitionerReview?.conditionalQuestionsNeeded) {
          setConditionalQuestionsNeeded(practitionerReview?.conditionalQuestionsNeeded)
        }

        const clinic = reviews.find((review) => review.entityType === ReviewType.CLINIC)?.entity as Clinic
        clinicContext.setClinic(clinic)

        if (practitionerReview && practitionerReview.rating > 0) {
          setReviewQuestions(true)
        }
      }
    })
    // If context is a dependency the useEffect will be a recurring call
    // eslint-disable-next-line
  }, [])

  const getImageUrl = (entity: Practitioner | Clinic) => {
    if ('imageUrl' in entity) return entity.imageUrl
    return undefined
  }

  const handleRedirect = () =>
    handleRedirectToThirdPartySite(surveyContext.survey?.redirectToUrl || '', queryParamsContext.embedded)

  if (showRedirectScreen) {
    return <RedirectScreen onRedirect={handleRedirect} />
  }

  if (!(review && clinicReview)) return <div />

  return (
    <ReviewScreen
      review={review}
      feedback={form.watch('feedback')}
      reviewType={ReviewType.PRACTITIONER}
      showSubmit={conditionalQuestionsShown || reviewQuestionsShown}
      showImprovements={reviewQuestionsShown}
      imageUrl={
        getImageUrl(review.entity) || getPublicAsset(defaultPractitionerImageUrl, 'assets/icons/doctor-silhouette.jpg')
      }
      onRatingUpdate={handleUpdateRating(review.reviewReferenceId, clinicReview.reviewReferenceId, review.entityType)}
      onSubmit={handleSubmit}
      setFeedback={(value) => {
        return form.setValue('feedback', value)
      }}
      onAddIssue={
        isAddIssueLoading ? noOp : handleAddIssue(review.reviewReferenceId, ReviewType.PRACTITIONER, addIssue)
      }
      onDeleteIssue={
        isDeleteIssueLoading ? noOp : handleDeleteIssue(review, ReviewType.PRACTITIONER, deleteIssue, updateReview)
      }
    >
      {conditionalQuestionsShown && (
        <ConditionalQuestions>
          {conditionalQuestionsNeeded.teethColor && (
            <ConditionalQuestion
              control={control}
              label={t('conditional_questions.teeth_color', { practitionerName: review.entity.name })}
              name="conditionalQuestions.teethColor"
            />
          )}
          {conditionalQuestionsNeeded.grindingProtection && (
            <ConditionalQuestion
              control={control}
              label={t('conditional_questions.grinding_protection', { practitionerName: review.entity.name })}
              name="conditionalQuestions.grindingProtection"
            />
          )}
          {conditionalQuestionsNeeded.teethPosition && (
            <ConditionalQuestion
              control={control}
              label={t('conditional_questions.teeth_position', { practitionerName: review.entity.name })}
              name="conditionalQuestions.teethPosition"
            />
          )}
        </ConditionalQuestions>
      )}
    </ReviewScreen>
  )
}

export default ReviewPractitioner
