import create from 'zustand'
import { Survey } from '../common/@types/interfaces'

type SurveyContext = {
  survey?: Survey
  setSurvey: (survey: Survey) => void
}

export const getSurvey = (state: SurveyContext): Survey | undefined => state.survey

const useSurveyContext = create<SurveyContext>((set) => ({
  survey: undefined,

  setSurvey: (survey: Survey) => set(() => ({ survey })),
}))

export default useSurveyContext
