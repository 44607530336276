import React from 'react'
import IssuesStateless, { Props } from './Issue'
import { IssuesByReviewCategory } from './Issue.helper'
import { ReviewCategory } from '../../../../common/@types/enums'

type StatelessProps = Omit<Props, 'options'>

const Issues = (props: StatelessProps & { category: ReviewCategory }) => {
  return <IssuesStateless options={IssuesByReviewCategory.get(props.category) || []} {...props} />
}

export default Issues
