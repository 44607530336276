import axios from 'axios'
import { Survey, UpdateReview } from '../@types/interfaces'
import { ReviewType } from '../@types/enums'
import { IssueType } from '../../screens/Review/components/Issue/Issue.helper'
import Logger from '../../services/logger'

const happyClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
})

happyClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.code !== 'ECONNABORTED') {
      Logger.error('Error from happyClient interceptor', {
        response: error.response,
        payload: error?.config?.data,
        baseURL: error?.config?.baseURL,
        url: error?.config?.url,
        method: error?.config?.method,
        location: window.location,
      })
    }

    throw error
  }
)

export interface CreateReviewSummaryParams {
  appointmentIds: string[]
  rating: number
  source?: string
}

export const createOrGetSurvey = async ({ appointmentIds, rating, source }: CreateReviewSummaryParams) => {
  const result = await happyClient.post<Survey>(`/api/survey/`, {
    appointmentReferenceIds: appointmentIds,
    rating,
    source,
  })

  return result.data
}

export const updateReview = async ({
  referenceId,
  feedback,
  rating,
  entityType,
  grindingProtection,
  teethColor,
  teethPosition,
}: UpdateReview) => {
  const type = entityType === ReviewType.PRACTITIONER ? 'practitioners' : 'clinics'
  const result = await happyClient.patch(`/api/reviews/${type}/${referenceId}`, {
    feedback,
    rating,
    grindingProtection,
    teethColor,
    teethPosition,
  })

  return result.data
}

export const submitSurvey = async (surveyReferenceId: string): Promise<Survey> => {
  const result = await happyClient.post(`/api/survey/${surveyReferenceId}/submit`)

  return result.data
}

export const getAppointmentsWithoutSurvey = async () => {
  const result = await happyClient.get(`/api/appointments?withSurvey=false`)

  return result.data
}

export const addIssueToReview = async ({
  reviewReferenceId,
  type,
  entityType,
}: {
  reviewReferenceId: string
  type: IssueType
  entityType: ReviewType
}) => {
  const entityTypePath = entityType === ReviewType.PRACTITIONER ? 'practitioners' : 'clinics'
  const result = await happyClient.post(`/api/review-issues/${entityTypePath}`, {
    reviewReferenceId,
    type: type,
  })

  return result.data
}

export const deleteIssueFromReview = async ({ id, entityType }: { id: string; entityType: ReviewType }) => {
  const type = entityType === ReviewType.PRACTITIONER ? 'practitioners' : 'clinics'
  const result = await happyClient.delete(`/api/review-issues/${type}/${id}`)

  return result.data
}

type PostEmailFeedbackProps = {
  patientReferenceId: string
  sendgridTemplateId: string
  rating: string
}

export const postEmailFeedback = async (payload: PostEmailFeedbackProps) => {
  const result = await happyClient.post('/api/email-feedbacks', payload)

  return result.data
}
