export enum ReviewType {
  CLINIC = 'CLINIC',
  PRACTITIONER = 'PRACTITIONER',
}

export enum ReviewCategory {
  CLINIC = 'CLINIC',
  DOCTOR = 'DOCTOR',
  EXPERT = 'EXPERT',
}

export enum SurveyStatus {
  SUBMITTED = 'SUBMITTED',
  NOT_SUBMITTED = 'NOT_SUBMITTED',
}
