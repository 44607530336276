import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useHistory } from 'react-router-dom'
import { postEmailFeedback } from '../../common/api/happy'
import { useDidMount } from '../../common/hooks/useDidMount'
import * as S from '../../screens/Finish/Finish.styles'
import { routes } from '../../config/routes'
import { Grid } from '@mui/material'

export const EmailFeedback = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()
  const { search } = useLocation()
  const history = useHistory()

  useDidMount(() => {
    const searchParams = new URLSearchParams(search)

    const patientReferenceId = searchParams.get('patientReferenceId')
    const sendgridTemplateId = searchParams.get('sendgridTemplateId')
    const rating = searchParams.get('rating')

    if (patientReferenceId && sendgridTemplateId && rating) {
      postEmailFeedback({ patientReferenceId, sendgridTemplateId, rating })
        .then(() => {
          history.replace(routes.finish)
        })
        .catch((e) => {
          setIsLoading(false)
        })
    } else {
      // in case of missing params - show error message as well
      setIsLoading(false)
    }
  })

  if (isLoading) {
    return <></>
  }

  return (
    <S.Container>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12}>
          <S.Title>{t('email_feedback_error')}</S.Title>
        </Grid>
      </Grid>
    </S.Container>
  )
}
