import i18n, { TFunction } from 'i18next'
import { initReactI18next } from 'react-i18next'
import { Backend } from '@dentalux/i18next-plugins'

import deTranslations from '../i18n/de.json'
import enTranslations from '../i18n/en.json'

const shouldUsePreview = ['development', 'preview'].includes(process.env.REACT_APP_ENV || '')

// inits I18next with contentful request to fetch translations, with fallback to local
export const init = (lng = 'de'): Promise<TFunction> => {
  return i18n
    .use(initReactI18next)
    .use(Backend)
    .init({
      backend: {
        applicationName: 'happy-frontend',
        fallbackResource: {
          en: enTranslations,
          de: deTranslations,
        },
        contentfulConfig: {
          usePreview: shouldUsePreview,
        },
      },
      lng,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
    })
}

// inits I18next with local translation
export const testInit = (lng = 'de'): Promise<TFunction> => {
  return i18n.use(initReactI18next).init({
    resources: {
      en: { translation: enTranslations },
      de: { translation: deTranslations },
    },
    lng,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })
}

export default i18n
