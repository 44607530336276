import { init, BrowserTracing, GlobalHandlers, Replay } from '@sentry/react'

const getReplayInstance = () => {
  if (!window.replayInstance) {
    window.replayInstance = new Replay()
    return window.replayInstance
  }

  return window.replayInstance
}

export const initializeSentry = (): void => {
  if (!process.env.REACT_APP_SENTRY_DSN) return

  init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing(),
      new GlobalHandlers({ onunhandledrejection: true, onerror: true }),
      getReplayInstance(),
    ],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENV,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  })
}
