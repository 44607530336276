import {
  MuiAlert,
  MuiButton,
  MuiTypography,
  shadows,
  MuiFormHelperText,
  typography,
  colors,
  createTheme,
} from '@dentalux/ui-library-core/cjs'

import '@dentalux/ui-library-core/cjs/typography.css'

const theme = createTheme({
  shadows,
  typography,
  components: {
    MuiTypography,
    MuiButton,
    MuiAlert,
    MuiFormHelperText,
  },
  palette: {
    mode: 'light',
    text: {
      primary: colors.grey[800],
    },
    background: {
      default: '#fff',
    },
    secondary: {
      main: colors.orange[400],
      ...colors.orange,
    },
    primary: {
      main: colors.aqua[800],
      ...colors.aqua,
    },
    grey: colors.grey,
    error: colors.error,
  },
})

export default theme
