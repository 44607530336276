import React, { useState } from 'react'
import { ReactComponent as RatingStarEmpty } from '../../../../assets/icons/rating-star.svg'
import { ReactComponent as RatingStarSelected } from '../../../../assets/icons/rating-star-selected.svg'
import { useTranslation } from 'react-i18next'
import * as S from './RateView.styles'

interface Props {
  referenceId: string
  reviewRating: number
  onUpdate: (rating: number) => void
  starSize?: 'small' | 'large'
  showStarLabel?: 'static' | 'hover'
  staticLabelValues?: number[]
}

const RateView: React.FC<Props> = ({
  onUpdate,
  reviewRating,
  referenceId,
  starSize,
  showStarLabel,
  staticLabelValues,
}) => {
  const { t } = useTranslation()

  const labels: { [index: string]: string } = {
    1: t('rating_label.very_poor'),
    2: t('rating_label.poor'),
    3: t('rating_label.acceptable'),
    4: t('rating_label.good'),
    5: t('rating_label.excellent'),
  }

  const [hoverRating, setHoverRating] = useState<number>(-2)
  const ratingHoverNormalized = hoverRating !== -2 ? hoverRating / 2 : null
  const ratingHoverLabel = ratingHoverNormalized ? labels[ratingHoverNormalized] : null

  const handleRatingChange = (_: unknown, newRate: number | null) => {
    if (newRate) {
      newRate = newRate * 2
      onUpdate(newRate)
    }
  }

  const handleRatingHover = (_: unknown, newHover: number) => {
    setHoverRating(newHover * 2)
  }

  const showStaticRating = (value: number, ratingToRender: number[]) => {
    return <>{ratingToRender.includes(value) && <S.RatingLabel>{labels[value]}</S.RatingLabel>}</>
  }

  const showHoverRating = (value: number) => {
    return <>{showStarLabel && ratingHoverNormalized === value && <S.RatingLabel>{ratingHoverLabel}</S.RatingLabel>}</>
  }

  const showRatingLabels = (labelRating: number) => {
    return (
      <>
        {showStarLabel === 'static' && staticLabelValues && showStaticRating(labelRating, staticLabelValues)}
        {showStarLabel === 'hover' && showHoverRating(labelRating)}
      </>
    )
  }

  const getRatingContainer = (props: { value: number }) => {
    const ratingNormalized = reviewRating / 2

    return (
      <S.Container data-testId={`review-${referenceId}-${props.value}`}>
        {props.value <= ratingNormalized && (
          <div>
            <RatingStarSelected />
          </div>
        )}
        {props.value > ratingNormalized && (
          <div>
            <RatingStarEmpty />
          </div>
        )}
        {showRatingLabels(props.value)}
      </S.Container>
    )
  }

  return (
    <S.Rating
      name={`review-${referenceId}-rating`}
      value={Math.floor(reviewRating / 2)}
      onChange={handleRatingChange}
      onChangeActive={handleRatingHover}
      size={starSize}
      IconContainerComponent={getRatingContainer}
    />
  )
}

export default RateView
