import React from 'react'
import * as S from './Issue.styles'
import { useTranslation } from 'react-i18next'
import ReviewIssue from '../../../../common/@types/reviewIssue'
import IssueOptions, { IssueType } from './Issue.helper'

export interface Props {
  selected: ReviewIssue[]
  options: IssueOptions[]
  addChip: (type: IssueType) => void
  deleteChip: (id: string) => void
}

const Issue: React.FC<Props> = ({ selected, options, addChip, deleteChip }) => {
  const { t } = useTranslation()
  const handleChipClick = (subCategoryOption: IssueOptions) => () => {
    const findSelected = selected.find((issue) => issue.type === subCategoryOption.type)
    if (findSelected) {
      deleteChip(findSelected.id)
    } else {
      addChip(subCategoryOption.type)
    }
  }

  const visualizeChips = () =>
    options.map((issueOption) => {
      const isChipSelected = selected.some((issue) => issue.type === issueOption.type)
      return (
        <S.Chip
          data-class={'issue-chip'}
          key={issueOption.title}
          color={isChipSelected ? 'primary' : 'secondary'}
          variant="outlined"
          label={t(issueOption.title)}
          onClick={handleChipClick(issueOption)}
        />
      )
    })

  return <S.Root>{visualizeChips()}</S.Root>
}

export default Issue
