import create from 'zustand'
import { Review } from '../common/@types/interfaces'
import { sortReviewsByEntityType } from '../helpers/Review.helper'
import { ReviewType } from '../common/@types/enums'
import ReviewIssue from '../common/@types/reviewIssue'

type ReviewContext = {
  reviews: Review[]
  setReviews: (reviews: Review[]) => void
  updateReview: ({ referenceId, value, feedback }: UpdateReviewProps) => void
}

export type UpdateReviewProps = {
  referenceId: string
  value?: number
  feedback?: string | null
  issues?: ReviewIssue[]
  conditionalQuestionsAnswers?: Review['conditionalQuestionsAnswers']
}

export const defaultConditionalQuestionsAnswers = {
  teethPosition: null,
  grindingProtection: null,
  teethColor: null,
}

export const defaultConditionalQuestions = {
  teethPosition: false,
  grindingProtection: false,
  teethColor: false,
}

export const getPractitionerReview = (state: ReviewContext): Review | undefined => {
  const review = state.reviews.find((review) => review.entityType === ReviewType.PRACTITIONER)

  return review
}

export const getClinicReview = (state: ReviewContext): Review | undefined =>
  state.reviews.find((review) => review.entityType === ReviewType.CLINIC)

const useReviewContext = create<ReviewContext>((set) => ({
  reviews: [],

  setReviews: (reviews: Review[]) => set(() => ({ reviews: sortReviewsByEntityType(reviews) })),

  updateReview: ({ referenceId, value, feedback, issues }: UpdateReviewProps) =>
    set((state: ReviewContext) => {
      const result = state.reviews
      const review = result.find((review) => review.reviewReferenceId === referenceId)

      if (review) {
        if (value) review.rating = value
        if (feedback !== undefined) review.feedback = feedback
        if (issues) review.issues = issues
      }

      return {
        reviews: result,
      }
    }),
}))

export default useReviewContext
