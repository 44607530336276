import create from 'zustand'
import { Clinic } from '../common/@types/interfaces'

type ClinicContext = {
  clinic?: Clinic
  setClinic: (clinic: Clinic) => void
}

export const getClinic = (state: ClinicContext): Clinic | undefined => state.clinic

const useClinicContext = create<ClinicContext>((set) => ({
  clinic: undefined,

  setClinic: (clinic: Clinic) => set(() => ({ clinic })),
}))

export default useClinicContext
