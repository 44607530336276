import React from 'react'
import * as S from './ClinicWrapper.styles'
import { useTranslation } from 'react-i18next'
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch'
import useClinicContext, { getClinic } from '../../store/ClinicContext'
import { Box } from '@mui/material'

const ClinicWrapper: React.FC = () => {
  const { t } = useTranslation()
  const clinic = getClinic(useClinicContext())

  return (
    <S.Container>
      <S.GridContainer>
        <S.LeftItem>
          <Box>
            <S.HoursLabel>{t('long-hours').split(' ').join('\xa0')}</S.HoursLabel>
            <S.Hours color="primary">Mo.&nbsp;-&nbsp;Fr.&nbsp;08:00&nbsp;-&nbsp;20:00</S.Hours>
          </Box>
        </S.LeftItem>
      </S.GridContainer>
      <S.MainGridContainer>
        {clinic ? (
          <S.ClinicBanner>
            <S.NameLogo />
            <S.ClinicName>{clinic.name}</S.ClinicName>
          </S.ClinicBanner>
        ) : (
          <S.Logo />
        )}
      </S.MainGridContainer>
      <S.GridContainer>
        <S.RightItem>
          <Box>
            <LanguageSwitch />
          </Box>
        </S.RightItem>
      </S.GridContainer>
    </S.Container>
  )
}

export default ClinicWrapper
