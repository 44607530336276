import { Grid, Typography, Container as ContainerBase, Box } from '@mui/material'
import { ReactComponent as Patient21Logo } from '../../assets/icons/patient-21-logo.svg'

import { styled } from '@mui/material'
import { matchesMobile } from '../../helpers/breakpoints'

export const Container = styled(Grid)(({ theme }) => ({
  minHeight: '78px',
  margin: 'auto',
  marginTop: theme.spacing(1),
  textAlign: 'center',
  backgroundColor: theme.palette.common.white,
  justifyContent: 'space-between',
  display: 'flex',
  flexWrap: 'wrap',
  maxWidth: 1224,
  alignItems: 'center',
}))

export const GridContainer = styled(Grid)(() => ({
  flexShrink: 0,
  flexGrow: 0,
  flexBasis: '25%',
}))

export const MainGridContainer = styled(GridContainer)(({ theme }) => ({
  flexBasis: '50%',
  margin: 'auto',

  [theme.breakpoints.down(800)]: {
    order: 3,
    marginTop: theme.spacing(4),
    flexBasis: '100%',
  },
}))

GridContainer.defaultProps = {
  item: true,
}

export const HoursLabel = styled(Typography)({
  textAlign: 'center',
  width: '100%',
})

HoursLabel.defaultProps = {
  variant: 'Lato Caption Small Bold',
}

export const Hours = styled(Typography)({})

Hours.defaultProps = {
  variant: 'Lato Emphasized 1',
}

export const ClinicBanner = styled(ContainerBase)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const Logo = styled(Patient21Logo)(({ theme }) => ({
  height: '90px',
  width: '75px',

  [matchesMobile(theme)]: {
    height: '30px',
  },
}))

export const ClinicName = styled(Typography)({})

ClinicName.defaultProps = {
  variant: 'Lato Body 1',
}

export const NameLogo = styled(Logo)(({ theme }) => ({
  marginRight: theme.spacing(2),
}))

export const LeftItem = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}))

LeftItem.defaultProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
}

export const RightItem = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(2),
}))

RightItem.defaultProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}
