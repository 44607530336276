import { Review } from '../common/@types/interfaces'
import { ReviewType } from '../common/@types/enums'
import { UseMutateAsyncFunction, useMutation } from 'react-query'
import { UpdateReviewProps } from '../store/ReviewContext'
import ReviewIssue from '../common/@types/reviewIssue'
import { IssueType } from '../screens/Review/components/Issue/Issue.helper'
import * as api from '../common/api/happy'
import { postParentMsg } from '../common/utils/MessageSender'
import { IframeMessageType } from '../common/@types/HappyMessages'
import { routes } from '../config/routes'
import Logger from '../services/logger'

// Validate that the survey has positive reviews
// In case users have mixed rating of 10 and less than 10
export const isReviewPositive = (reviews: Review[]) => {
  return reviews.every((review: Review) => review.rating === 10)
}

// Order by Practitioner first then Clinic
export const sortReviewsByEntityType = (reviews: Review[]) =>
  reviews.sort((firstReview, secondReview) => {
    if (firstReview.entityType > secondReview.entityType) return -1
    if (firstReview.entityType < secondReview.entityType) return 1
    return 0
  })

export const getAppointmentText = (rating: number, type: ReviewType): string | undefined => {
  const appoitmentTextMapByScore = new Map([
    [2, 'how_was_appointment.1_star'],
    [4, 'how_was_appointment.2_star'],
    [6, 'how_was_appointment.3_star'],
    [8, 'how_was_appointment.4_star'],
    [10, 'how_was_appointment.5_star'],
  ])

  if (rating === 0)
    return `${type === ReviewType.PRACTITIONER ? 'how_was_appointment.practitioner' : 'how_was_appointment.clinic'}`
  return appoitmentTextMapByScore.get(rating)
}

export const getRateText = (rating: number): string => {
  if (rating === 8) return 'please_select.do_better'
  if (rating === 10) return 'please_select.liked'
  return 'please_select.wrong'
}

export const handleDeleteIssue =
  (
    review: Review,
    reviewType: ReviewType,
    deleteIssue: UseMutateAsyncFunction<void, unknown, { id: string; entityType: ReviewType }, unknown>,
    updateReview: (updatedReviewProps: Omit<UpdateReviewProps, 'referenceId'>) => void
  ) =>
  (id: string): void => {
    deleteIssue({
      id,
      entityType: reviewType,
    }).then(() => {
      updateReview({
        issues: review?.issues.filter((issue) => issue.id !== id),
      })
    })
  }

export const handleAddIssue =
  (
    reviewReferenceId: string,
    reviewType: ReviewType,
    addIssue: UseMutateAsyncFunction<
      ReviewIssue,
      unknown,
      { reviewReferenceId: string; type: IssueType; entityType: ReviewType },
      unknown
    >
  ) =>
  (type: IssueType): void => {
    addIssue({
      reviewReferenceId: reviewReferenceId,
      type,
      entityType: reviewType,
    })
  }

export const useAddIssueMutation = (
  reviewValue: Review | undefined,
  onUpdate: (updateProps: Omit<UpdateReviewProps, 'referenceId'>) => void
) => {
  const addIssueMutation = useMutation(api.addIssueToReview, {
    onSuccess: (data: ReviewIssue) => {
      const issues = reviewValue?.issues || []
      onUpdate({
        issues: [
          ...issues,
          {
            id: data.id,
            type: data.type,
          },
        ],
      })
    },
  })

  return addIssueMutation
}

export const handleRedirectWithScreen = (embedded: boolean, showRedirectScreen: () => void, redirectUrl?: string) => {
  return redirectUrl ? showRedirectScreen() : handleRedirectToFinish(embedded)
}

export const handleRedirect = (embedded: boolean, redirectUrl?: string) => {
  return redirectUrl ? handleRedirectToThirdPartySite(redirectUrl, embedded) : handleRedirectToFinish(embedded)
}

export const handleRedirectToFinish = (embedded: boolean) => {
  window.location.replace(`#${routes.finish}?embedded=${embedded}`)
}

// Third Party sites used are: Jameda.de for practitioners, Google My Business for clinics
export const handleRedirectToThirdPartySite = (redirectUrl: string, embedded: boolean) => {
  Logger.debug('Redirect to outside website', {
    redirectUrl,
  })
  if (embedded) {
    postParentMsg({
      type: IframeMessageType.REDIRECT_TO_JAMEDA,
      payload: redirectUrl,
    })
  } else window.location.assign(redirectUrl)
}

export const handleRedirectToUrl = (redirectUrl: string, embedded: boolean) => {
  Logger.debug('Redirect to outside website', {
    redirectUrl,
  })
  if (embedded) {
    postParentMsg({
      type: IframeMessageType.REDIRECT,
      payload: redirectUrl,
    })
  } else window.location.assign(redirectUrl)
}

export const useDeleteIssueMutation = () => useMutation(api.deleteIssueFromReview)
