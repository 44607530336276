import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './Create.styles'
import { Appointment } from '../../common/@types/interfaces'
import RateView from '../Review/components/RateView'
import { routes } from '../../config/routes'
import { useQueryParameterContext } from '../../providers/QueryParameterProvider'
import { Autocomplete, Button, FormControl, Grid, TextField } from '@mui/material'

interface Props {
  appointments: Appointment[]
}

const CreateScreen = ({ appointments }: Props) => {
  const { t } = useTranslation()
  const [appointment, setAppointment] = useState<Appointment | null>(null)
  const [rating, setRating] = useState<number>(0)
  const isFormInvalid = appointment?.referenceId == null

  const { embedded } = useQueryParameterContext()

  const handleUpdateRating = (rating: number) => {
    setRating(rating)
  }

  const handleSubmitButton = () => {
    if (appointment?.referenceId != null) {
      window.location[embedded ? 'replace' : 'assign'](
        `#${routes.reviewPractitioner}?appointmentReferenceIds=${appointment.referenceId}&selectedRating=${rating}`
      )
    }
  }

  const createLabel = (separator: string, firstPart?: string, secondPart?: string) => {
    return [firstPart, secondPart].filter(Boolean).join(separator)
  }

  return (
    <S.Container>
      <Grid spacing={3} justifyContent="center">
        <Grid item xs={12}>
          <FormControl variant="outlined">
            <Autocomplete
              openOnFocus
              onChange={(_, option) => setAppointment(option)}
              value={appointment}
              options={appointments || []}
              style={{ width: 450 }}
              getOptionLabel={(option) => `${option.title} - ${option.referenceId}`}
              renderOption={(params: object, option) => {
                return (
                  <S.Option
                    {...params}
                    primary={createLabel(' - ', option.title, option.referenceId)}
                    secondary={createLabel(', ', option.patient?.firstName, option.patient?.lastName)}
                  />
                )
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('create.label_appointments')}
                  variant="outlined"
                  data-testid="create-input"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <RateView referenceId={appointment?.referenceId || ''} reviewRating={rating} onUpdate={handleUpdateRating} />
        </Grid>

        <Button disabled={isFormInvalid} onClick={handleSubmitButton}>
          {t('create.submit_button')}
        </Button>
      </Grid>
    </S.Container>
  )
}

export default CreateScreen
