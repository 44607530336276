import React, { useEffect, useState } from 'react'
import * as S from './Redirect.style'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'

export interface Props {
  onRedirect: () => void
}

const MAX_PROGRESS_VALUE = 100
const INTERVAL_DECREMENT = 2.5

const Redirect: React.FC<Props> = ({ onRedirect }) => {
  const { t } = useTranslation()
  const [progress, setProgress] = useState(MAX_PROGRESS_VALUE)

  //  The logic to controls the countdown circular widget
  useEffect(() => {
    if (progress === 0) {
      onRedirect()
      return
    }

    const timeout = setTimeout(async () => {
      setProgress(progress - INTERVAL_DECREMENT)
    }, 100)

    return () => {
      clearTimeout(timeout)
    }
  }, [progress, onRedirect])

  return (
    <S.Root>
      <S.DescriptionContainer>
        <S.Title>{t('redirect.almost_there')}</S.Title>
        <S.Subtitle>{t('redirect.your_positive_feedback')}</S.Subtitle>
        <Button onClick={onRedirect}>{t('redirect.rate_now')}</Button>
      </S.DescriptionContainer>
      <S.ImageContainer>
        <S.Image />
      </S.ImageContainer>
    </S.Root>
  )
}

export default Redirect
